import {useEffect, useState} from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
    Backdrop,
    Box,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Stack,
    TextField
} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";

import {useGetQuizQuery, useUpdateQuizEndMutation} from "../../api/quiz";
import {ErrorAlert} from "../ErrorAlert";
import {t} from "../../i18n/i18n";

interface MyProps {
    quizid?: number;
    open: boolean;

    close(quizId: number): any;

    cancel(): any;
}

const formInitialState = {
    id: undefined as number | undefined,
    end: null as string | null
};

export const EditQuizEndDialog = ({quizid, open, close, cancel}: MyProps) => {
    const [formData, setFormData] = useState(formInitialState);
    const {data, isSuccess, isFetching, isLoading, isError, error} =
        useGetQuizQuery(quizid || 0, {skip: quizid === undefined});
    const [updateQuizEnd, updateQuizStatus] = useUpdateQuizEndMutation();

    dayjs.extend(utc);
    dayjs.extend(timezone);

    useEffect(() => {
        if (quizid === undefined) {
            setFormData(formInitialState);
        } else if (!isFetching && isSuccess) {
            setFormData(data);
        }
    }, [quizid, data, isSuccess, isFetching])

    const handleSave = () => {
        // @ts-ignore
        updateQuizEnd(formData).unwrap().then(() => {
            const qId = formData.id;
            setFormData(formInitialState);
            close(qId ?? 0);
        });
    }

    const handleCancel = () => {
        setFormData(formInitialState);
        cancel();
    }

    return (
        <Dialog open={open} onClose={handleCancel} maxWidth={'lg'} fullWidth>
            {isError && <ErrorAlert title={t('apiError.getQuizInfo')} error={error} />}
            {updateQuizStatus.isError && <ErrorAlert title={t('apiError.updateQuiz')} error={updateQuizStatus.error} />}

            {isLoading && <CircularProgress />}

            <DialogTitle>{t('admin.quiz.editEnd.title', {id: formData.id})}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('admin.quiz.editEnd.description')}</DialogContentText>
                <Box component={'form'}
                     sx={{
                         display: 'grid',
                         gridTemplateColumns: {sm: '1fr'},
                         gap: 2
                     }}
                     noValidate
                     autoComplete={'off'}>
                    <FormControl fullWidth>
                        <TextField
                            id={'title'}
                            margin={'dense'}
                            label={t('admin.quiz.editEnd.quizTitle')}
                            fullWidth
                            variant={'standard'}
                            value={data?.title}
                            disabled
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <Stack direction={'row'} spacing={3}>
                            <DateTimePicker
                                label={t('admin.quiz.editEnd.startDatetime')}
                                value={data?.start !== undefined ? dayjs(data.start) : null}
                                format={'YYYY-MM-DDTHH:mm'}
                                ampm={false}
                                disabled
                                onChange={() => {
                                }}
                            />
                            <DateTimePicker
                                label={t('admin.quiz.editEnd.endDatetime')}
                                value={dayjs(formData.end)}
                                format={'YYYY-MM-DDTHH:mm'}
                                ampm={false}
                                onChange={value => setFormData({
                                    ...formData,
                                    end: value !== null ? value.toISOString() : ''
                                })}
                            />
                        </Stack>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSave}
                    disabled={!isSuccess || updateQuizStatus.isLoading}
                >{t('generic.button.save')}</Button>
                <Button
                    onClick={handleCancel}
                >{t('generic.button.cancel')}</Button>
            </DialogActions>
            <Backdrop open={updateQuizStatus.isLoading}>
                <CircularProgress/>
            </Backdrop>
        </Dialog>
    );
};
